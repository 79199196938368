<template>
  <div class="card card-custom">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">
          {{ $t("Employees Documents") }}
          <span class="d-block text-muted pt-2 font-size-sm">{{
            $t("Employee documents management")
          }}</span>
        </h3>
      </div>
      <div class="card-toolbar">
        <euro-checkbox v-model="expiring" :text="$t('Expiring')" size="lg" is-switch class="mr-6 mt-5"
          @input="$refs.table.refresh()"></euro-checkbox>
        <euro-select v-model="userSelected" class-name="mb-0 min-w-250px mr-4" placeholder="Select employee"
          :options="users" @input="$refs.table.refresh()"></euro-select>
        <euro-select v-model="typeSelected" class-name="mb-0 min-w-250px" placeholder="Select type" :options="types"
          @input="$refs.table.refresh()"></euro-select>
      </div>
    </div>
    <div class="card-body">
      <datatable ref="table" :table-props="tableProps" :total="total" :options.sync="tableOptions"
        :per-page-options="perPageOptions">
        <!-- BUSY STATE -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-3"></b-spinner>
            <strong>{{ $t("loading...") }}</strong>
          </div>
        </template>

        <template #[`cell.persona`]="{ item }">
          <div class="d-flex align-items-center">
            <div v-if="!item?.persona_avatar" class="symbol symbol-40 symbol-light-primary flex-shrink-0">
              <span class="symbol-label font-size-h4 font-weight-bold">{{
                item.persona_name[0]
              }}</span>
            </div>
            <div v-else class="symbol symbol-40 symbol-light-primary flex-shrink-0">
              <img :src="item?.persona_avatar" alt="avatar" />
            </div>
            <div class="ml-3">
              <div class="text-capitalize font-weight-bold">
                {{ item.persona_name }}
              </div>
              <div class="text-muted font-size-xs">
                {{ item.persona_email }}
              </div>
            </div>
          </div>
        </template>

        <template #[`cell.document_type`]="{ item }">
          <span :class="`font-weight-bold label label-inline label-light-${docTypes[item.document_type].labelColor
            }`">
            {{ docTypes[item.document_type].typeName }}
          </span>
        </template>

        <template #[`cell.racap`]="{ item }">
          <div class="text-dark-75 font-weight-bolder font-size-lg w-40">
            <template v-if="!docTypes[item.document_type].name(item).endsWith('xxxxxx')">
              {{ docTypes[item.document_type].name(item) }}
            </template>
            <template v-else>
              <span>{{
                docTypes[item.document_type].name(item).slice(0, -6)
              }}</span>
              <b-tooltip :target="'unauth-label-' + item.id">
                {{ $t("Unauthorized to see this data") }}
              </b-tooltip>
              <label :id="'unauth-label-' + item.id">*****</label>
            </template>
          </div>

          <template v-if="item.document_type == 'PostingCertificate'">
            <span v-for="(country, idx) in item.data.posting_country" :key="idx"
              class="label label-inline label-light-success font-weight-bolder mr-2 mb-2">
              {{ country.name }}
            </span>
          </template>

          <template v-if="item.document_type == 'EducationLevel' &&
            item.data?.attainment_date != undefined
            ">
            <span class="text-muted text-size-sm">{{
              $t("Attainment date") +
              ": " +
              formattedDate(item.data?.attainment_date)
            }}</span>
          </template>

          <template v-if="item.document_type == 'FiscalID'">
            <template v-if="!isExpired(item)">
              <div>
                <span class="label label-success label-dot mr-2"></span><span class="font-weight-bold text-success">{{
                  $t("Valid")
                }}</span>
              </div>
            </template>
            <template v-else>
              <div>
                <span class="label label-danger label-dot mr-2"></span><span class="font-weight-bold text-danger">{{
                  $t("Expired")
                }}</span>
              </div>
            </template>
          </template>

          <template v-if="item.document_type == 'Passport' && item.data.date_of_issue && item.is_authorized" >
            <div class="text-muted text-size-sm">
              {{
                $t("Language") + ": " + extendedLanguage(item.data.doc_language)
              }}
            </div>
            <div class="text-muted text-size-sm">
              {{
                $t("Release") + ": " + formattedDate(item.data.date_of_issue)
              }}
            </div>
          </template>

          <template v-if="item.document_type == 'ResidencePermit'">
            <div v-if="item.is_authorized" class="text-muted text-size-sm">
              {{ item.data.permit_kind }}
            </div>
          </template>

          <template v-if="item.document_type == 'EmployeeContract'">
            <span class="label label-inline label-light-success font-weight-bolder mr-2 mt-2">
              {{ item.data.country.name }}
            </span>
            <span class="text-muted text-size-sm mt-2">
              {{ item.data.national_contract_level }}
            </span>
          </template>

          <template v-if="item.document_type == 'IdentityDocument' && item.is_authorized">
            <div class="text-muted text-size-sm">
              {{
                $t("Release") + ": " + formattedDate(item.data.date_of_issue)
              }}
            </div>
          </template>
          <template v-if="item.document_type == 'EuropeanDrivingLicense'">
            <div class="text-muted text-size-sm">
              <span v-for="(categoryItem, index) in item.data.category_set" :key="index" class="font-weight-bold">
                <div v-if="item.is_authorized">
                  <span class="text-success">
                    {{ categoryItem.category + ":" }}
                  </span>
                  <span>
                    <span>{{
                      $t("Expiring date") +
                      " - " +
                      formattedDate(categoryItem.date_to)
                    }}</span>
                  </span>
                  <br />
                </div>
              </span>
            </div>
          </template>
          <template v-if="item.document_type == 'EuropeanHealthInsuranceCard'">
            <div class="text-muted text-size-sm">
              {{
                $t("Card number") + ": " + (item.is_authorized ? item.data.card_identification_number : "xxxxxx")
              }}
            </div>
            <div class="text-muted text-size-sm">
              {{
                $t("Institution number") + ": " + (item.is_authorized ? item.data.institution_identification_number : "xxxxxx")
              }}
            </div>
          </template>

          <template v-if="item.document_type == 'KnownLanguage'">
            <span class="text-muted text-size-sm mr-2">{{ item.level }}</span>
            <template v-if="item.data.certification">
              <span><span class="label label-success label-dot mr-2"></span><span class="font-weight-bold text-success">{{
                $t("Certified")
              }}</span></span>
            </template>
            <template v-else><span class="label label-danger label-dot mr-2"></span><span
                class="font-weight-bold text-danger">{{
                  $t("Not certified")
                }}</span></template>
          </template>
        </template>

        <template #[`cell.valid_from`]="{ item }">
          <span class="font-weight-bolder text-muted">{{
            formattedDate(item?.date_start)
          }}</span>
        </template>
        <template #[`cell.valid_to`]="{ item }">
          <span class="font-weight-bolder text-muted">{{
            formattedDate(item?.date_end)
          }}</span>
        </template>
        <template #[`cell.actions`]="{ item, row }">
          <div class="d-flex">
            <b-button v-b-tooltip.hover
              :title="item.attachment ? titleDownloadButton(item.is_authorized) : titleNotPermissionAndNotContentButton(item.is_authorized) "
              class="btn btn-icon btn-light btn-sm mr-2"
              :class="!isAfterSale ? hasAttachmentColorSecondary(item) : 'btn-hover-secondary'"
              @click="!isAfterSale ? (item.is_authorized && item.attachment ? hasAttachment(item) : null) : null">
              <span class="svg-icon svg-icon-md" :class="!isAfterSale ? hasAttachmentColorMuted(item) : 'svg-icon-muted'">
                <inline-svg src="/media/svg/icons/General/Clip.svg" />
              </span>
            </b-button>
            <b-button v-b-tooltip.hover :title="$t('View Details')"
              class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2" @click="edit(item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/media/svg/icons/General/User.svg" />
              </span>
            </b-button>
          </div>
        </template>
      </datatable>
    </div>
  </div>
</template>

<script>
import PhysicalPersonLanguageService from "@/core/services/physical-person/physical-person-language.service";
import PhysicalPersonEducationService from "@/core/services/physical-person/physical-person-education.service";
import PhysicalPersonResidencePermitService from "@/core/services/physical-person/physical-person-residence-permit.service";
import PhysicalPersonFiscalService from "@/core/services/physical-person/physical-person-fiscal.service";
import PhysicalPersonPassportService from "@/core/services/physical-person/physical-person-passport.service";
import PhysicalPersonIdentityService from "@/core/services/physical-person/physical-person-identitydocument.service";
import PhysicalPersonEuropeanHealthInsuranceService from "@/core/services/physical-person/physical-person-europeanhealthinsurance.service";
import PhysicalPersonA1ModuleService from "@/core/services/physical-person/physical-person-a1-module.service";
import PhysicalPersonEuropeanDrivingLicenseService from "@/core/services/physical-person/physical-person-europeandrivinglicense.service";
import EmployeeContractService from "@/core/services/employee/employee-contract.service";
import CompanyRegistrationCertificateService from "@/core/services/company/company-registration-certificate.service";
import CompanyInsuranceCertificateService from "@/core/services/company/company-insurancecertificate.service";
import CompanySocialSecurityService from "@/core/services/company/company-socialsecurity.service";
import CompanyVatService from "@/core/services/company/company-vat.service";
import EmployeeService from "@/core/services/employee/employee.service";
import DateService from "@/core/services/date.service";
import { mapGetters, mapState } from "vuex";
import { backendErrorSwal } from "@/core/helpers/swal";
import moment from "moment";
const today = new Date();
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  perPageOptions,
  tableOptions,
} from "@/core/config/datatable.config.js";
import PhysicalPersonSuitabilityWorkService from "@/core/services/physical-person/physical-person-suitability-work.service";
import PhysicalPersonVisaService from "@/core/services/physical-person/physical-person-visa-service";

export default {
  data() {
    return {
      items: [],
      users: [],
      types: [],
      userSelected: null,
      typeSelected: null,
      isBusy: true,
      fields: [
        {
          label: this.$t("Employee"),
          key: "persona",
          sortable: true,
        },
        {
          label: this.$t("Type"),
          key: "document_type",
          sortable: false,
          class: "align-middle",
        },
        {
          label: this.$t("Name"),
          key: "racap",
          class: "align-middle",
        },
        {
          label: this.$t("Valid From"),
          key: "valid_from",
          sortable: true,
          class: "align-middle",
        },
        {
          label: this.$t("Valid To"),
          key: "valid_to",
          sortable: true,
          class: "align-middle",
        },
        {
          label: this.$t("Actions"),
          key: "actions",
          class: "align-end col-actions",
        },
      ],
      total: 0,
      perPageOptions: perPageOptions,
      tableOptions: tableOptions,

      searchFilter: "",
      expiring: false,
    };
  },
  computed: {
    ...mapState("constants", ["countries", "languages"]),
    ...mapGetters("user", ["dateFormat", "isAfterSale"]),
    ...mapGetters("constants", ["countriesMap"]),
    docTypes() {
      return {
        FiscalID: {
          typeName: "Fiscal ID",
          labelColor: "danger",
          name: (item) =>
            item.country + " " + (!this.buttonDisabled(item.is_authorized) ? item.label : "xxxxxx"),
          isEditable: true,
          isDeletable: true,
          modal: "physical-fiscal-create-modal",
          downLoadFunc: (item) => PhysicalPersonFiscalService.getOne(item),
          delFunc: (item) => PhysicalPersonFiscalService.delete(item.id),
        },
        Passport: {
          typeName: "Passport",
          labelColor: "info",
          name: (item) =>
            item.country + " " + (!this.buttonDisabled(item.is_authorized) ? item.label : "xxxxxx"),
          isEditable: true,
          isDeletable: true,
          modal: "create-passport-modal",
          downLoadFunc: (item) => PhysicalPersonPassportService.getOne(item.id),
          delFunc: (item) => PhysicalPersonPassportService.delete(item),
        },
        Visa: {
          typeName: "Visa",
          labelColor: "warning",
          name: (item) =>
            item.country + " " + (!this.buttonDisabled(item.is_authorized) ? item.label : "xxxxxx"),
          isEditable: true,
          isDeletable: true,
          modal: "create-visa-modal",
          downLoadFunc: (item) => PhysicalPersonVisaService.getOne(item.id),
          delFunc: (item) => PhysicalPersonVisaService.delete(item),
        },
        EmployeeContract: {
          typeName: "Contract",
          labelColor: "info",
          name: (item) =>
            !this.buttonDisabled(item.is_authorized)? item.data?.national_contract : "xxxxxx",
          isEditable: true,
          isDeletable: true,
          downLoadFunc: (item) => EmployeeContractService.getOne(item.id), //window.open(item.attachment, "_blank"),
          delFunc: (item) => EmployeeContractService.delete(item.id),
        },
        IdentityDocument: {
          typeName: "ID Document",
          labelColor: "info",
          name: (item) =>
            item.country + " " + (!this.buttonDisabled(item.is_authorized) ? item.label : "xxxxxx"),
          isEditable: true,
          isDeletable: true,
          modal: "create-identify-modal",
          downLoadFunc: (item) => PhysicalPersonIdentityService.getOne(item),
          delFunc: (item) => PhysicalPersonIdentityService.delete(item),
        },
        EducationLevel: {
          typeName: "Education Level",
          labelColor: "success",
          name: (item) => item.label,
          isEditable: true,
          isDeletable: true,
          modal: "create-education-modal",
          downLoadFunc: (item) => PhysicalPersonEducationService.getOne(item),
          delFunc: (item) => PhysicalPersonEducationService.delete(item),
        },
        KnownLanguage: {
          typeName: "Language",
          labelColor: "warning",
          name: (item) => item.data.level + " " + item.label,
          isEditable: true,
          isDeletable: true,
          modal: "create-language-modal",
          downLoadFunc: (item) => PhysicalPersonLanguageService.getOne(item),
          delFunc: (item) => PhysicalPersonLanguageService.delete(item.id),
        },
        EuropeanHealthInsuranceCard: {
          typeName: "EU health insurance",
          labelColor: "warning",
          name: (item) => (!this.buttonDisabled(item.is_authorized) ? item.label : "xxxxxx"),
          isEditable: true,
          isDeletable: true,
          modal: "create-eu-health-modal",
          downLoadFunc: (item) =>
            PhysicalPersonEuropeanHealthInsuranceService.getOne(item.id),
          delFunc: (item) =>
            PhysicalPersonEuropeanHealthInsuranceService.delete(item.id),
        },
        EuropeanDrivingLicense: {
          typeName: "EU driving license",
          labelColor: "success",
          name: (item) => (!this.buttonDisabled(item.is_authorized) ? item.label : "xxxxxx"),
          isEditable: true,
          isDeletable: true,
          modal: "create-eu-driving-modal",
          downLoadFunc: (item) =>
            PhysicalPersonEuropeanDrivingLicenseService.getOne(item.id),
          delFunc: (item) =>
            PhysicalPersonEuropeanDrivingLicenseService.delete(item.id),
        },
        PostingCertificate: {
          typeName: "Posting certificate",
          labelColor: "primary",
          attachmentField: "doc",
          name: (item) => item.country + " " + (!this.buttonDisabled(item.is_authorized) ? item.data.certificate_kind: "xxxxxx"),
          isEditable: true,
          isDeletable: true,
          modal: "create-a-module",
          downLoadFunc: (item) => PhysicalPersonA1ModuleService.getOne(item.id),
          delFunc: (item) => PhysicalPersonA1ModuleService.delete(item.id),
        },
        ResidencePermit: {
          typeName: "Residence Permit",
          labelColor: "info",
          name: (item) => (!this.buttonDisabled(item.is_authorized) ? item.label : "xxxxxx"),
          isEditable: true,
          isDeletable: true,
          modal: "create-permit-modal",
          updateFunc: (item, itemId) =>
            PhysicalPersonResidencePermitService.update(item, itemId),
          downLoadFunc: (item) =>
            PhysicalPersonResidencePermitService.getOne(item.id),
          delFunc: (item) => PhysicalPersonResidencePermitService.delete(item),
        },
        CertificateOfSuitabilityForWork: {
          typeName: "Certificate Suitability Of Work",
          labelColor: "success",
          attachmentField: "doc",
          name: (item) => (!this.buttonDisabled(item.is_authorized) ? item.label : "xxxxxx"),
          isEditable: true,
          isDeletable: true,
          modal: "create-suitability-work",
          updateFunc: (item, itemId) =>
            PhysicalPersonSuitabilityWorkService.update(item, itemId),
          downLoadFunc: (item) =>
            PhysicalPersonSuitabilityWorkService.getOne(item.id),
          delFunc: (item) =>
            PhysicalPersonSuitabilityWorkService.delete(item.id),
        },
        PayCheck: {
          typeName: "Paycheck",
          labelColor: "success",
          name: (item) => item.label,
          isEditable: true,
          isDeletable: false,
          modal: "create-paycheck-modal",
          downLoadFunc: function (item) {
            item.attachments.forEach((element) => {
              window.open(element.attachment, "_blank");
            });
          },
        },
        extracompanyvat_set: {
          typeName: "VAT",
          labelColor: "warning",
          name: (item) => item.vat | "broken",
          isEditable: true,
          isDeletable: true,
          modal: "create-vat-modal",
          downLoadFunc: (item) => CompanyVatService.getOne(item.id),
          delFunc: (item) => CompanyVatService.delete(item.id),
        },
        companyregistrationcertificate_set: {
          typeName: "National CCIAA certificate",
          labelColor: "danger",
          name: (item) =>
            (!this.buttonDisabled(item.is_authorized) ? item.registration_number : "xxxxxx") |
            "broken",
          isEditable: true,
          isDeletable: true,
          modal: "create-registration-certificate-modal",
          downLoadFunc: (item) =>
            CompanyRegistrationCertificateService.getOne(item.id),
          delFunc: (item) =>
            CompanyRegistrationCertificateService.delete(item.id),
        },
        insurancecertificate_set: {
          typeName: "Insurance - Third Party",
          labelColor: "primary",
          name: (item) =>
            (!this.buttonDisabled(item.is_authorized) ? item.number : "xxxxxx") | "broken",
          isEditable: true,
          isDeletable: true,
          modal: "create-ins-third-party-modal",
          downLoadFunc: (item) =>
            CompanyInsuranceCertificateService.getOne(item.id),
          delFunc: (item) => CompanyInsuranceCertificateService.delete(item.id),
        },
        social_security_certificates: {
          typeName: "Social Security Office Registration",
          labelColor: "success",
          name: (item) =>
            (!this.buttonDisabled(item.is_authorized) ? item.number : "xxxxxx") | "broken",
          isEditable: true,
          isDeletable: true,
          modal: "create-social-security-modal",
          downLoadFunc: (item) => CompanySocialSecurityService.getOne(item.id),
          delFunc: (item) => CompanySocialSecurityService.delete(item.id),
        },
      };
    },
    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields,
        filter: this.searchFilter,
      };
    },

  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Employee") },
      { title: this.$t("Documents") },
    ]);
    if (this.$route.params.expiring) {
      this.expiring = this.$route.params.expiring
    }
  },
  methods: {
    buttonDisabled(is_authorized) {
      return !is_authorized || this.isAfterSale;
    },
    //se c'è un documento, se non hai i permessi ti dirà che non c'e autorizzazione
    titleDownloadButton(is_authorized) {
      return this.buttonDisabled(is_authorized) ? this.$t("You do not have permission to download attachments") : this.$t("Download attachments")
    },
    //Se non c'è nessun documento valuta se hai i permessi, se non hai i permessi restituisce non autorizzato, se ce l'hai ti dirà nessun contenuto
    titleNotPermissionAndNotContentButton(is_authorized) {
      return this.buttonDisabled(is_authorized) ? this.$t("You do not have permission to download attachments") : this.$t("No attachments found")
    },
    hasAttachment(item) {
      return item.attachment ? this.download(item) : null
    },
    hasAttachmentColorSecondary(item) {
      return item.attachment ? 'btn-hover-primary' : 'btn-hover-secondary'
    },
    hasAttachmentColorMuted(item) {
      return item.attachment ? 'svg-icon-primary' : 'svg-icon-muted'
    },
    itemProvider(ctx, callback) {
      let fields = "";
      if (this.typeSelected) {
        fields += this.typeSelected;
      }
      EmployeeService.getDocuments({
        perPage: ctx.perPage,
        page: ctx.currentPage,
        sortBy: ctx.sortBy || "valid_to",
        search: ctx.filter,
        sortDesc: ctx.sortDesc,
        fields: fields,
        id: this.userSelected,
        expiring: this.expiring,
      })
        .then((res) => {
          let out = res.results;
          if (this.users.length === 0) {
            this.users = res.persona_objs;
          }
          if (this.types.length === 0) {
            this.types = res.document_types.map((key) => {
              return {
                value: key,
                text: this.docTypes[key].typeName,
              };
            });
          }
          try {
            out.map((item) => {
              if (!this.docTypes[item.document_type]) {
                console.error(
                  `docTypes does not have a property ${item.document_type}`
                );
              } else if (
                typeof this.docTypes[item.document_type].name !== "function"
              ) {
                console.error(
                  `docTypes[${item.document_type}] does not have a method named 'name'`
                );
              } else {
                const name = this.docTypes[item.document_type].name(item);
                if (typeof name !== "string") {
                  console.error(
                    `docTypes[${item.document_type}].name does not return a string value`
                  );
                }
              }
            });
          } catch (error) {
            console.error(error);
          }
          this.total = res.count;
          callback(out);
        })
        .catch(() => {
          callback([]);
        });
    },
    isExpired(item) {
      return moment(item.valid_to).isBefore(moment(today));
    },
    formattedDate(date) {
      if (!date) return "";
      return DateService.format(date, this.dateFormat);
    },
    extendedLanguage(iso) {
      const language = this.languages.find((el) => el.iso_639_1 == iso);
      if (!language) return iso;
      return language.name_en;
    },
    async download(item) {
      if (item.document_type === "PayCheck") {
        await this.downloadZip(item.id);
      } else {
        await this.downloadItem(item);
      }
    },
    async downloadItem(item) {
      const downLoadFunc = this.docTypes[item.document_type].downLoadFunc;
      try {
        const res = await downLoadFunc(item);

        window.open(res.attachment || res.doc, "_blank");
      } catch (err) {
        let message = "";
        if (err?.response?.status === 400) {
          message = err.response.data;
        } else {
          message =
            err?.response?.data?.detail ?? this.$t("Something went wrong!");
        }
        backendErrorSwal(message, err);
      }
    },
    async downloadZip(id) {
      await EmployeeService.donwloadPaycheckAttachmentZip(id)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const contentDisposition = response.headers["content-disposition"];
          let fileName = "unknown";
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename=(.+)/);
            if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
          }
          link.setAttribute("download", fileName);
          link.click();
          link.remove();
        })
        .catch((err) => {
          backendErrorSwal(
            err?.response?.data?.detail ??
            this.$t(
              "Unable to download the paycheck zip, please try again later"
            )
          );
          console.log(err);
        });
    },
    edit(item) {
      this.$router.push({
        name: "detail-physical-documents",
        params: { userID: item.persona_id },
      });
    },
  },
};
</script>
<style scoped>
.text-capitalize {
  text-transform: capitalize;
}
</style>
