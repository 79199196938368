import api from "../api.service";

class PhysicalPersonFiscalService {
  get PATH() {
    return "/physicalperson-fiscalid";
  }

  async getAll(personID, { perPage, page, sortBy = null, search = null, sortDesc = null }) {
    const offset = (page - 1) * perPage;
    const params = { persona: personID, limit: perPage, offset, search }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }

    const res = await api.get(`${this.PATH}/`, {
      params,
    });
    return res.data;
  }

  async getOne(fiscal) {
    const res = await api.get(`${this.PATH}/${fiscal.id}/`);
    return res.data;
  }

  async create(fiscalID) {
    const res = await api.post(`${this.PATH}/`, fiscalID);
    return res.data;
  }

  async update(fiscalID, id) {
    const res = await api.patch(`${this.PATH}/${id}/`, fiscalID);
    return res.data;
  }

  async delete(fiscalID) {
    await api.delete(`${this.PATH}/${fiscalID}`);
  }

  async generateFiscalId(body) {
    const res = await api.post(`${this.PATH}/generate_fiscal_id/`, body);
    return res.data;
  }
}

export default new PhysicalPersonFiscalService();
