var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header flex-wrap border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title"
  }, [_c('h3', {
    staticClass: "card-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Employees Documents")) + " "), _c('span', {
    staticClass: "d-block text-muted pt-2 font-size-sm"
  }, [_vm._v(_vm._s(_vm.$t("Employee documents management")))])])]), _c('div', {
    staticClass: "card-toolbar"
  }, [_c('euro-checkbox', {
    staticClass: "mr-6 mt-5",
    attrs: {
      "text": _vm.$t('Expiring'),
      "size": "lg",
      "is-switch": ""
    },
    on: {
      "input": function input($event) {
        return _vm.$refs.table.refresh();
      }
    },
    model: {
      value: _vm.expiring,
      callback: function callback($$v) {
        _vm.expiring = $$v;
      },
      expression: "expiring"
    }
  }), _c('euro-select', {
    attrs: {
      "class-name": "mb-0 min-w-250px mr-4",
      "placeholder": "Select employee",
      "options": _vm.users
    },
    on: {
      "input": function input($event) {
        return _vm.$refs.table.refresh();
      }
    },
    model: {
      value: _vm.userSelected,
      callback: function callback($$v) {
        _vm.userSelected = $$v;
      },
      expression: "userSelected"
    }
  }), _c('euro-select', {
    attrs: {
      "class-name": "mb-0 min-w-250px",
      "placeholder": "Select type",
      "options": _vm.types
    },
    on: {
      "input": function input($event) {
        return _vm.$refs.table.refresh();
      }
    },
    model: {
      value: _vm.typeSelected,
      callback: function callback($$v) {
        _vm.typeSelected = $$v;
      },
      expression: "typeSelected"
    }
  })], 1)]), _c('div', {
    staticClass: "card-body"
  }, [_c('datatable', {
    ref: "table",
    attrs: {
      "table-props": _vm.tableProps,
      "total": _vm.total,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center text-primary my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle mr-3"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t("loading...")))])], 1)];
      },
      proxy: true
    }, {
      key: "cell.persona",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [!(item !== null && item !== void 0 && item.persona_avatar) ? _c('div', {
          staticClass: "symbol symbol-40 symbol-light-primary flex-shrink-0"
        }, [_c('span', {
          staticClass: "symbol-label font-size-h4 font-weight-bold"
        }, [_vm._v(_vm._s(item.persona_name[0]))])]) : _c('div', {
          staticClass: "symbol symbol-40 symbol-light-primary flex-shrink-0"
        }, [_c('img', {
          attrs: {
            "src": item === null || item === void 0 ? void 0 : item.persona_avatar,
            "alt": "avatar"
          }
        })]), _c('div', {
          staticClass: "ml-3"
        }, [_c('div', {
          staticClass: "text-capitalize font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.persona_name) + " ")]), _c('div', {
          staticClass: "text-muted font-size-xs"
        }, [_vm._v(" " + _vm._s(item.persona_email) + " ")])])])];
      }
    }, {
      key: "cell.document_type",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          class: "font-weight-bold label label-inline label-light-".concat(_vm.docTypes[item.document_type].labelColor)
        }, [_vm._v(" " + _vm._s(_vm.docTypes[item.document_type].typeName) + " ")])];
      }
    }, {
      key: "cell.racap",
      fn: function fn(_ref3) {
        var _item$data, _item$data2;
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "text-dark-75 font-weight-bolder font-size-lg w-40"
        }, [!_vm.docTypes[item.document_type].name(item).endsWith('xxxxxx') ? [_vm._v(" " + _vm._s(_vm.docTypes[item.document_type].name(item)) + " ")] : [_c('span', [_vm._v(_vm._s(_vm.docTypes[item.document_type].name(item).slice(0, -6)))]), _c('b-tooltip', {
          attrs: {
            "target": 'unauth-label-' + item.id
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Unauthorized to see this data")) + " ")]), _c('label', {
          attrs: {
            "id": 'unauth-label-' + item.id
          }
        }, [_vm._v("*****")])]], 2), item.document_type == 'PostingCertificate' ? _vm._l(item.data.posting_country, function (country, idx) {
          return _c('span', {
            key: idx,
            staticClass: "label label-inline label-light-success font-weight-bolder mr-2 mb-2"
          }, [_vm._v(" " + _vm._s(country.name) + " ")]);
        }) : _vm._e(), item.document_type == 'EducationLevel' && ((_item$data = item.data) === null || _item$data === void 0 ? void 0 : _item$data.attainment_date) != undefined ? [_c('span', {
          staticClass: "text-muted text-size-sm"
        }, [_vm._v(_vm._s(_vm.$t("Attainment date") + ": " + _vm.formattedDate((_item$data2 = item.data) === null || _item$data2 === void 0 ? void 0 : _item$data2.attainment_date)))])] : _vm._e(), item.document_type == 'FiscalID' ? [!_vm.isExpired(item) ? [_c('div', [_c('span', {
          staticClass: "label label-success label-dot mr-2"
        }), _c('span', {
          staticClass: "font-weight-bold text-success"
        }, [_vm._v(_vm._s(_vm.$t("Valid")))])])] : [_c('div', [_c('span', {
          staticClass: "label label-danger label-dot mr-2"
        }), _c('span', {
          staticClass: "font-weight-bold text-danger"
        }, [_vm._v(_vm._s(_vm.$t("Expired")))])])]] : _vm._e(), item.document_type == 'Passport' && item.data.date_of_issue && item.is_authorized ? [_c('div', {
          staticClass: "text-muted text-size-sm"
        }, [_vm._v(" " + _vm._s(_vm.$t("Language") + ": " + _vm.extendedLanguage(item.data.doc_language)) + " ")]), _c('div', {
          staticClass: "text-muted text-size-sm"
        }, [_vm._v(" " + _vm._s(_vm.$t("Release") + ": " + _vm.formattedDate(item.data.date_of_issue)) + " ")])] : _vm._e(), item.document_type == 'ResidencePermit' ? [item.is_authorized ? _c('div', {
          staticClass: "text-muted text-size-sm"
        }, [_vm._v(" " + _vm._s(item.data.permit_kind) + " ")]) : _vm._e()] : _vm._e(), item.document_type == 'EmployeeContract' ? [_c('span', {
          staticClass: "label label-inline label-light-success font-weight-bolder mr-2 mt-2"
        }, [_vm._v(" " + _vm._s(item.data.country.name) + " ")]), _c('span', {
          staticClass: "text-muted text-size-sm mt-2"
        }, [_vm._v(" " + _vm._s(item.data.national_contract_level) + " ")])] : _vm._e(), item.document_type == 'IdentityDocument' && item.is_authorized ? [_c('div', {
          staticClass: "text-muted text-size-sm"
        }, [_vm._v(" " + _vm._s(_vm.$t("Release") + ": " + _vm.formattedDate(item.data.date_of_issue)) + " ")])] : _vm._e(), item.document_type == 'EuropeanDrivingLicense' ? [_c('div', {
          staticClass: "text-muted text-size-sm"
        }, _vm._l(item.data.category_set, function (categoryItem, index) {
          return _c('span', {
            key: index,
            staticClass: "font-weight-bold"
          }, [item.is_authorized ? _c('div', [_c('span', {
            staticClass: "text-success"
          }, [_vm._v(" " + _vm._s(categoryItem.category + ":") + " ")]), _c('span', [_c('span', [_vm._v(_vm._s(_vm.$t("Expiring date") + " - " + _vm.formattedDate(categoryItem.date_to)))])]), _c('br')]) : _vm._e()]);
        }), 0)] : _vm._e(), item.document_type == 'EuropeanHealthInsuranceCard' ? [_c('div', {
          staticClass: "text-muted text-size-sm"
        }, [_vm._v(" " + _vm._s(_vm.$t("Card number") + ": " + (item.is_authorized ? item.data.card_identification_number : "xxxxxx")) + " ")]), _c('div', {
          staticClass: "text-muted text-size-sm"
        }, [_vm._v(" " + _vm._s(_vm.$t("Institution number") + ": " + (item.is_authorized ? item.data.institution_identification_number : "xxxxxx")) + " ")])] : _vm._e(), item.document_type == 'KnownLanguage' ? [_c('span', {
          staticClass: "text-muted text-size-sm mr-2"
        }, [_vm._v(_vm._s(item.level))]), item.data.certification ? [_c('span', [_c('span', {
          staticClass: "label label-success label-dot mr-2"
        }), _c('span', {
          staticClass: "font-weight-bold text-success"
        }, [_vm._v(_vm._s(_vm.$t("Certified")))])])] : [_c('span', {
          staticClass: "label label-danger label-dot mr-2"
        }), _c('span', {
          staticClass: "font-weight-bold text-danger"
        }, [_vm._v(_vm._s(_vm.$t("Not certified")))])]] : _vm._e()];
      }
    }, {
      key: "cell.valid_from",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(_vm._s(_vm.formattedDate(item === null || item === void 0 ? void 0 : item.date_start)))])];
      }
    }, {
      key: "cell.valid_to",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(_vm._s(_vm.formattedDate(item === null || item === void 0 ? void 0 : item.date_end)))])];
      }
    }, {
      key: "cell.actions",
      fn: function fn(_ref6) {
        var item = _ref6.item,
          row = _ref6.row;
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-sm mr-2",
          class: !_vm.isAfterSale ? _vm.hasAttachmentColorSecondary(item) : 'btn-hover-secondary',
          attrs: {
            "title": item.attachment ? _vm.titleDownloadButton(item.is_authorized) : _vm.titleNotPermissionAndNotContentButton(item.is_authorized)
          },
          on: {
            "click": function click($event) {
              !_vm.isAfterSale ? item.is_authorized && item.attachment ? _vm.hasAttachment(item) : null : null;
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md",
          class: !_vm.isAfterSale ? _vm.hasAttachmentColorMuted(item) : 'svg-icon-muted'
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/Clip.svg"
          }
        })], 1)]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm mr-2",
          attrs: {
            "title": _vm.$t('View Details')
          },
          on: {
            "click": function click($event) {
              return _vm.edit(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/User.svg"
          }
        })], 1)])], 1)];
      }
    }], null, true)
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }