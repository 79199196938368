import api from "../api.service";

// AKA POSTING CERTIFICATE
export default {
  get PATH() {
    return "physicalperson-postingcertificate";
  },

  async getAll(
    personId,
    { perPage, page, sortBy = null, search = null, sortDesc = null }
  ) {
    const offset = (page - 1) * perPage;
    const params = {
      persona: personId,
      limit: perPage,
      offset,
      ordering: sortBy,
      search,
    };
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }

    const res = await api.get(`${this.PATH}/`, {
      params,
    });

    return res.data;
  },

  async getOne(id) {
    const res = await api.get(`${this.PATH}/${id}/`);
    return res.data;
  },

  async create(body) {
    const res = await api.post(`${this.PATH}/`, body);
    return res.data;
  },

  async update(body, id) {
    const res = await api.patch(`${this.PATH}/${id}/`, body);
    return res.data;
  },

  async delete(id) {
    await api.delete(`${this.PATH}/${id}`);
  },

  async options() {
    return await api.options(`${this.PATH}/`);
  },

  async getPostingCertificateCountries() {
    const res = await api.get(
      `${this.PATH}/get_posting_certificate_countries/`
    );
    return res.data;
  },
};
